<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Owing_road')">
                <auto-complete
                  ref="select"
                  @dataChange="(v) => (temParkData = v)"
                  slaveRelations="0,1"
                  @valueChange="completeValue"
                  :operationId="formInline.operationId"
                ></auto-complete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Toll_collector')">
                <el-input
                  v-model="formInline.operatorName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.toll_road')">
                <auto-complete
                  ref="select1"
                  @dataChange="(v) => (temParkDataPay = v)"
                  slaveRelations="0,1"
                  @valueChange="completeValue1"
                  :dataSource="1"
                  :operationId="formInline.operationId"
                ></auto-complete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_period')">
                <timeRangePick
                  v-if="isviftime"
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  searchData();
                  page = 1;
                "
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
          <tabCard :cardLists="cardLists"></tabCard>
        </el-form>
      </div>
      <!-- <div class="total-wrapper">
        <div class="item-wrapper">
          <div class="text">停车记录</div>
          <span class="unit"
            ><span class="bold-wrap">{{ totalObj.parkRecordNum || 0 }}</span
            >个</span
          >
        </div>
        <div class="item-wrapper">
          <div class="text">实收金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.money
                  ? totalObj.money.toString().length > 8
                    ? (totalObj.money / 100 / 10000).toFixed(2)
                    : (totalObj.money / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{
              totalObj.money && totalObj.money.toString().length > 8
                ? "万元"
                : "元"
            }}
          </span>
        </div>
      </div> -->
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            show-overflow-tooltip
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import picDetail from "../../../components/picDetail/picDetail";
import autoComplete from "@/components/autocomplete";
import exportFile from "@/components/exportFile/exportFile.vue";
import { dateFormat } from "@/common/js/public.js";
import tabCard from "@/components/tabCard/index.vue";
import InfoList from "../../../components/infoList/infoList";

export default {
  name: "arrearsInquiries",
  data() {
    return {
      cardLists: [
        { title: "停车记录", number: 0, unit: "个" },
        { title: "欠费金额", number: 0, unit: "元" },
      ],
      defalutDate: [],
      temParkDataPay: {},
      temParkData: {},
      plateNumber: "",
      streetList: [],
      area: "",
      street: "",
      parkPage: 1,
      parkPage1: 1,
      activeName: "first",
      parkData: [],
      parkData1: [],
      rowData: {},
      roleList: [],
      isEdit: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      entryExitType: [],
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      dialogVisible: false,
      tableOrderdata: [],
      infoDetail: {},
      areaList: [],
      parkList: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "120",
          formatter: (row) => {
            if (/无/g.test(row.plateNumber)) {
              return this.$t("list.Unlicensed_car");
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
        },
        {
          prop: "parentParkName",
          label: this.$t("list.Owing_road"),
          width: "80",
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "180",
        },
        {
          prop: "exitTime",
          label: this.$t("list.Appearance_time"),
          width: "180",
        },
        {
          prop: "strParkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "money",
          label: this.$t("list.Actual_received_amount"),
          width: "100",
          formatter: (row) => {
            return row.money == 0 ? "0.00" : row.money / 100 + "元";
          },
        },
        // , {
        //   prop: 'shouldPay',
        //   label: '实收金额',
        //   width: '100',
        //   formatter: (row) => {
        //     return row.shouldPay == 0 ? '0.00' : row.shouldPay / 100 + '元'
        //   }
        // },
        {
          prop: "channelTypeDesc",
          label: this.$t("list.Payment_channels"),
          width: "100",
        },
        // {
        //   prop: 'channelTypeDesc',
        //   label: '支付渠道',
        //   width: '100'
        // },
        {
          prop: "operatorName",
          label: this.$t("list.Toll_collector"),
          width: "100",
        },
        {
          prop: "parentPayParkName",
          label: this.$t("list.toll_road"),
          width: "100",
        },
        {
          prop: "payTime",
          label: this.$t("list.capture_expends_time"),
          width: "180",
        },
      ],
      parkDetail: {},
      tableData: [],
      formInline: {
        parkId: "",
        payParkId: "",
        carId: "",
        operatorName: "",
        startTime: "",
        endTime: "",
        operationId: "",
        name: `欠费追缴`,
      },
      exportData: {},
      isviftime: true,
      totalObj: {},
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
        this.temParkData = {};
      }
    },
    $route(to, from) {
      if (this.$route.params.pdaManagerName) {
        this.defalutDate = [
          this.$route.params.startTime + " 00:00:00",
          this.$route.params.endTime + " 23:59:59",
        ];
        this.formInline.operatorName = this.$route.params.pdaManagerName;
        this.formInline.operationId = this.$route.params.operationId;
        this.isviftime = false;
        setTimeout(() => {
          this.timeChange([
            this.$route.params.startTime + " 00:00:00",
            this.$route.params.endTime + " 23:59:59",
          ]);
          this.isviftime = true;
          this.searchData();
        }, 300);
      }
    },
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    indexMethod(index) {
      return index + 1;
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          }
        });
    },
    completeValue(item) {
      this.formInline.parkId = item;
    },
    completeValue1(item) {
      this.formInline.payParkId = item;
    },
    handleSelect(item) {
      this.formInline.carId = item.carId;
      this.page = 1;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    handleCurrentChange1(val) {
      this.page1 = val;
      this.searchData();
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        // let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        // time = time / (24 * 60 * 60 * 1000);
        // if (time >= 31 && !this.formInline.operatorName) {
        //   this.$alert("查询时间不能大于31天");
        //   return false;
        // } else {
        return true;
        // }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },

    /**
     * 统计结果
     */
    getTotalList(formInline) {
      this.formInline.areaId = this.streetId ? this.streetId : this.areaId;
      this.$axios
        .get("/acb/2.0/payOrder/arrearTotal", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.cardLists[0].number = res.value.parkRecordNum || 0;
            this.cardLists[1].number = res.value.money
              ? res.value.money.toString().length > 8
                ? (res.value.money / 100 / 10000).toFixed(2)
                : (res.value.money / 100).toFixed(2)
              : "0.00";
            this.cardLists[1].unit =
              res.value.money && res.value.money.toString().length > 8
                ? "万元"
                : "元";
            this.totalObj = res.value || {};
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 搜索
    searchData() {
      // this.tableData = [];
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.$refs.select.setShowVal(this.temParkData.parkName);
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      if (
        !this.formInline.payParkId &&
        Object.keys(this.temParkDataPay).length
      ) {
        this.$refs.select1.setShowVal(this.temParkDataPay.parkName);
        this.formInline.payParkId = this.temParkDataPay.parkId;
        this.temParkDataPay = {};
      }
      if (!this.showLog()) {
        return;
      }
      this.formInline.areaId = this.streetId ? this.streetId : this.areaId;
      this.$axios
        .get("/acb/2.0/payOrder/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
            this.getTotalList(this.formInline);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((e) => {
          this.tableData = [];
          this.totalObj = {};
        });
    },
  },
  components: {
    // InfoList,
    // picDetail,
    autoComplete,
    exportFile,
    timeRangePick,
    tabCard,
  },
  created() {
    if (this.$route.params.pdaManagerName) {
      // this.defalutDate = [this.$route.params.startTime + ' 00:00:00', this.$route.params.endTime + ' 23:59:59']
      this.defalutDate = [
        this.$route.params.startTime,
        this.$route.params.endTime,
      ];
      this.formInline.operatorName = this.$route.params.pdaManagerName;
      this.formInline.operationId = this.$route.params.operationId;
    } else {
      this.defalutDate = [
        this.dataTimeRest() + " 00:00:00",
        this.dataTimeRest() + " 23:59:59",
      ];
    }
  },
  mounted() {
    // this.formInline = {
    //   parkId: '',
    //   payParkId: '',
    //   carId: '',
    //   operatorName: this.$route.params.pdaManagerName || '',
    //   operationId: this.$route.params.operationId || '',
    //   startTime: this.formInline.startTime,
    //   endTime: this.formInline.endTime,
    //   name: `欠费追缴`
    // }
    // 导出数据
    this.exportData = {
      url: "/acb/2.0/payOrder/export",
      methods: "get",
      data: this.formInline,
    };
    this.getRoleList();
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.conborder {
  border-top: 1px solid #C0CCDA;
  overflow: hidden;
  padding: 10px;
}

.content {
  // background: #fff;
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px 0px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.tableWrapper {
  padding: 0 20px 20px;
}

.total-wrapper {
  padding: 0 20px;
  margin-bottom: 22px;

  .item-wrapper {
    width: 88px;
    margin-right: 16px;
    padding: 6px 16px;
    display: inline-block;
    background: #F4F8FF;
    border-radius: 4px;
  }

  .text {
    line-height: 17px;
    color: #425466;
    font-size: 12px;
  }

  .bold-wrap {
    padding-right: 2px;
    position: relative;
    top: 1px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0f6eff;
    line-height: 25px;
  }

  .unit {
    color: #6C7293;
    font-size: 12px;
  }
}
</style>
<style scoped>
.parkTitle {
  border-bottom: 1px solid #e1e1e1;
}

.parkTitle span {
  text-align: center;
  display: inline-block;
  width: 100px;
  line-height: 30px;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
}

.pic {
  margin-top: 20px;
}

.pic img {
  width: 200px;
  height: 200px;
}

.picTitle {
  line-height: 30px;
}

.detail1 {
  line-height: 25px;
}
</style>
